@import "./index.scss";

$image_load_1: transparent;
$image_load_2: #00897b90;

h1 {
	font-family: 'Oswald', sans-serif;
}

h3 {
	font-style: italic;
}

.title-content {
	background-color: black;
	border-radius: 2em;
}

@keyframes image-preload {

	0% {
		background-color: $image_load_1;
	}

	25% {
		background-color: $image_load_2;
	}

	50% {
		background-color: $image_load_1;
	}

	75% {
		background-color: $image_load_2;
	}

	100% {
		background-color: $image_load_1;
	}

}